import React from "react"
import { graphql, useStaticQuery } from "gatsby"
export default () => {

  return (
    <section>
      <div className="container px-5 py-24 mx-auto">
        <h1>Trusted By:</h1>
        <div class="grid grid-cols-5 gap-4">
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          <img src="https://images.prismic.io/prismic-website/5e2b7733-7e81-43ac-b2f8-187ec576c032_netflix.svg?auto=compress,format" />
          

        </div>
      </div>
    </section>
  )
}
