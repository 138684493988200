import React from "react"
import Layout from "../components/Layout"
import Testimonials from "./components/Testimonials"
import Feature from "./components/Feature"
import Hero from "./components/Hero"
import Skills from "./components/Skills"
import CustomerLogos from "./components/CustomerLogos"

export default () => {
  return (
    <Layout>
      <Hero />
      <CustomerLogos />
      <Skills />
      <Feature />
      <Testimonials />
    </Layout>
  )
}
